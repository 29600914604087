<template>
  <div>
    <article id="page-multicolumn" class="page-section text-center py-5">
      <header class="page-section-header container">
        <div class="row justify-content-center">
          <div class="col-11 col-sm-10 col-md-8">
            <h2 class="page-section-title">Bio-Calculators</h2>
            <p class="page-section-text">
              A collection of calculators made easy for Life Science
              researchers.
            </p>
          </div>
        </div>
      </header>

      <section class="layout-multicolumn container">
        <div class="row justify-content-center family-sans text-uppercase">
          <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <div>
              <div>
                <a href="/molarity">
                  <OlabMolaritySVGGen
                    name="Molarity"
                    textPosX="45"
                    textPosY="215"
                  />
                </a>
              </div>
            </div>
          </section>
          <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <div>
              <div>
                <a href="/dilution">
                  <OlabDilutionSVGGen
                    name="Dilution"
                    textPosX="50"
                    textPosY="215"
                  />
                </a>
              </div>
            </div>
          </section>
          <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <div>
              <div>
                <a href="/mediumbuffer">
                  <OlabMediumSVGGen
                    name="Medium"
                    textPosX="58"
                    textPosY="215"
                  />
                </a>
              </div>
            </div>
          </section>
          <section class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <div>
              <div>
                <a href="/quick">
                  <OlabMediumSVGGen name="Quick" textPosX="75" textPosY="215" />
                </a>
              </div>
            </div>
          </section>
          <!-- TODO: Remove v-if="false" when this calc is ready -->
          <section v-if="true" class="mb-5 col-10 col-md-6 col-lg-4 col-xl-3">
            <div>
              <div>
                <a href="/microbialgrowth">
                  <OlabMicrobialGrowthSVGGen
                    name="Growth"
                    textPosX="50"
                    textPosY="215"
                  />
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src
import OlabMolaritySVGGen from "@/components/OlabMolaritySVGGen";
import OlabDilutionSVGGen from "@/components/OlabDilutionSVGGen";
import OlabMediumSVGGen from "@/components/OlabMediumSVGGen";
import OlabMicrobialGrowthSVGGen from "@/components/OlabMicrobialGrowthSVGGen";

export default {
  name: "BioCalculators",
  components: {
    OlabMolaritySVGGen,
    OlabDilutionSVGGen,
    OlabMediumSVGGen,
    OlabMicrobialGrowthSVGGen
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-section {
  padding: 50px 0;
  background-color: #fdf2e9;
  background-image: var(--gray-gradient);
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-size: 500%;
  animation: bg-animation 100s infinite alternate,
    bg-animation 60s infinite alternate;
  box-shadow: 0 0 3em rgba(0, 0, 0, 0.15);
}

@keyframes bg-animation {
  0% {
    background-position: top left;
  }
  100% {
    background-position: bottom right;
  }
}

.page-section-title {
  margin: 0, 0, 0.5em;
  font-weight: 900;
  font-size: 3.5rem;
  background-image: var(--color-gradient);
  background-clip: text;
  color: transparent;
  background-size: 500%;
  animation: bg-animation 30s infinite alternate;
}

.page-section-text {
  margin: 0, 0, 0.5em;
  font-weight: 500;
  font-size: 1.5rem;
  background-image: var(--color-gradient);
  background-clip: text;
  color: transparent;
  background-size: 500%;
  animation: bg-animation 60s infinite alternate;
}

// .neon-body {
//   place-items: center;
//   background: hsl(323 21% 16% / 0);
//   color: var(--clr-pink-neon);
//   padding: 20%;
// }
// .neon-button {
//   // display: inline-block;
//   cursor: pointer;
//   text-decoration: none;
//   color: var(--clr-pink-neon);
//   border: var(--clr-pink-neon) 0.25em solid;
//   border-radius: 0.5em;
//   text-shadow: 0 0 0.1em hsl(0 0% 100% / 0.5), 0 0 0.25em var(--clr-pink-neon);
//   box-shadow: inset 0 0 1em 0 var(--clr-pink-neon),
//     0 0 1.5em 0 var(--clr-pink-neon);
// }

// .neon-button:hover,
// .neon-button:focus {
//   background: var(--clr-green-neon);
//   border: var(--clr-green-neon) 0.25em solid;
//   border-radius: 0.5em;
//   text-shadow: 0 0 0.1em hsl(0 0% 100% / 0.5), 0 0 0.25em var(--clr-green-neon);
//   box-shadow: inset 0 0 1em 0 var(--clr-green-neon),
//     0 0 1.5em 0 var(--clr-green-neon);
// }
</style>
