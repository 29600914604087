<template>
  <div>
    <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(65,52)">
        <g transform="scale(8)" fill="#cccccc">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.5 7.5V14.5H14.5V7.5H1.5ZM1 6C0.447715 6 0 6.44772 0 7V15C0 15.5523 0.447715 16 1 16H15C15.5523 16 16 15.5523 16 15V7C16 6.44772 15.5523 6 15 6H1Z"
            fill="pink"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 1H4V12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12V1ZM3 0V12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12V0H3Z"
            fill="#cccccc"
          />
          <path
            d="M2.5 0.5C2.5 0.223858 2.72386 0 3 0H7C7.27614 0 7.5 0.223858 7.5 0.5C7.5 0.776142 7.27614 1 7 1H3C2.72386 1 2.5 0.776142 2.5 0.5Z"
            fill="#cccccc"
          />
          <path
            d="M4.5 5H5.5V12C5.5 12.2761 5.27614 12.5 5 12.5L L5 12.5C4.72386 12.5 4.5 12.2761 4.5 12V5Z"
            fill="yellow"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 1H10V12C10 12.5523 10.4477 13 11 13C11.5523 13 12 12.5523 12 12V1ZM9 0V12C9 13.1046 9.89543 14 11 14C12.1046 14 13 13.1046 13 12V0H9Z"
            fill="#cccccc"
          />
          <path
            d="M8.5 0.5C8.5 0.223858 8.72386 0 9 0H13C13.2761 0 13.5 0.223858 13.5 0.5C13.5 0.776142 13.2761 1 13 1H9C8.72386 1 8.5 0.776142 8.5 0.5Z"
            fill="#cccccc"
          />
          <path
            d="M10.5 8.5H11.5V12C11.5 12.2761 11.2761 12.5 11 12.5L L11 12.5C10.7239 12.5 10.5 12.2761 10.5 12V8.5Z"
            fill="red"
          />
        </g>
      </g>
      <text
        :x="textPosX"
        :y="textPosY"
        fill="#ff6f91"
        style="font-weight: 900; font-size: 2rem"
      >
        {{ name }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "OlabDilutionSVGGen",
  props: ["name", "textPosX", "textPosY"]
};
</script>
